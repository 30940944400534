body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.MuiDrawer-root a[href='#/results'] {
  display: none;
}

.MuiDrawer-root a[href='#/responses'] {
  display: none;
}

.MuiDrawer-root a[href='#/sponsorStudentBridges'] {
  display: none;
}

.MuiDrawer-root a[href='#/sponsorStudentBridgesBySponsorId'] {
  display: none;
}

.MuiDrawer-root a[href='#/sponsorCodes'] {
  display: none;
}

.MuiDrawer-root a[href='#/students'] {
  display: none;
}